.timeline {
  padding: 10px 30px 46px;
  margin-top: 20px;
  min-height: 513px;
}

.tm-container {
  position: relative;
}

.tm-container:before {
  background: #eaeaea; /* Old browsers */
  background: -moz-linear-gradient(top, #eaeaea 1%, #eaeaea 75%, #ffffff 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1%, #eaeaea),
    color-stop(75%, #eaeaea),
    color-stop(100%, #ffffff)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #eaeaea 1%,
    #eaeaea 75%,
    #ffffff 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #eaeaea 1%, #eaeaea 75%, #ffffff 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #eaeaea 1%, #eaeaea 75%, #ffffff 100%); /* IE10+ */
  background: linear-gradient(to bottom, #eaeaea 1%, #eaeaea 75%, #ffffff 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eaeaea', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
  bottom: 0;
  content: "";
  left: 1px;
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
}

.tm {
  margin-bottom: 10px;
  padding-left: 30px;
  padding-top: 5px;
  position: relative;
}

.tm.bg-primary:after {
  background: inherit;
}

.tm:after {
  background: #9a9a9a;
  border: 3px solid #ffffff;
  content: "";
  height: 14px;
  left: -5px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 14px;
}

.tm .tm-header h6 {
  color: #666666;
  font-size: 12px;
}

.tm .tm-header p span.current {
  background: #199cdb;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  color: #ffffff;
  padding: 2px 6px;
  margin-left: 3px;
}

.key-profile .btn-sm {
  font-size: 0.7em;
}

.dashboard-card {
  min-height: 365px;
}

/*
    Overlay Header
*/

/*
background: linear-gradient(to right, #129bfb 0%, #2ca8ff 60%, #78c5fb 100%);
*/

.overlay-bg {
  /* fallback for old browsers */
  background: #141e30; /* fallback for old browsers */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0c2646),
    color-stop(60%, #204065),
    to(#2a5788)
  );
  background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
  background-size: 105%;
}
.overlay-header {
  min-height: 260px;
  padding-top: 80px;
  padding-bottom: 45px;
  position: relative;
}

.overlay-header .header .title {
  color: #ffffff;
}

.overlay-header .header .category {
  max-width: 600px;
  color: rgba(255, 255, 255, 0.5);
  margin: 0 auto;
  font-size: 13px;
}

.overlay-header .header .category a {
  color: #ffffff;
}

.overlay-header-sm {
  min-height: 135px;
}

.overlay-header-lg {
  min-height: 380px;
}

/*
    Overlay Content
*/

.main-panel > .overlay-content {
  padding: 0 30px;
  min-height: calc(100vh - 123px);
  margin-top: -45px;
}

@media screen and (max-width: 768px) {
  .main-panel .overlay-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*
    hero-img
*/

/*img.hero-img:hover {*/
/*transition: filter .5s ease-in-out;*/
/*-webkit-filter: grayscale(0%);*/
/*filter: grayscale(0%);*/
/*}*/
/*img.hero-img {*/
/*-webkit-filter: grayscale(100%);*/
/*filter: grayscale(100%);*/
/*}*/
img.hero-img {
  max-width: 200px;
}

/*
TestimonialCarousel
*/

.testimony-user-mini {
  width: 50px;
  height: 50px;
}

.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}

.center h3 {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.card.testimony {
  margin: 0;
  margin: 35px 0;
  /*width: 90%;*/
}

.slick-slide .testimonial-item {
  margin: 5px 12px;
  line-height: 15px;
}

.slick-slide .testimonial-item .card {
  margin: 0;
  padding: 0.6rem;
}

.slick-slide .testimonial-item .card-body {
  height: 140px;
  font-size: 0.6rem;
  padding: 0;
}

.slick-slide .testimonial-item .card-footer {
  padding: 0;
}

@media screen and (max-width: 768px) {
  .slick-slide .testimonial-item .card {
    max-height: 300px;
    min-height: 300px;
  }
  .slick-slide .testimonial-item .card-footer {
    font-size: 0.7rem;
  }
  .slick-slide .testimonial-item .card-body {
    height: 226px;
  }
}

.slick-slide .testimonial-item .card-body .testimonial-item-desc {
  text-overflow: ellipsis;
  display: flex;
  overflow: hidden;
  height: 100%;
  /* width: 100%; */
  margin: 0;
}

.avatar-mini {
  width: 80px;
  height: 80px;
}

.skill-keyword {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.skill-keyword-bg {
  background-color: rgba(42, 87, 136, 0.6);
}
.skill-keyword-bg:hover {
  background-color: rgba(42, 87, 136, 0.6);
}

.badge-secondary {
  background-color: #2a5788;
}

.btn-info {
  background-color: #2a5788;
}

.slick-prev:before,
.slick-next:before {
  color: #2a5788;
  opacity: 1;
}
